import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Typography from 'common/Typography';
import Banner from 'components/Banner';
import RelatedList from 'containers/RelatedList';

import './ArticleDetailPage.scss';

const ArticleDetailPage: FC<ArticleDetailPageTypes.ArticleDetailPageProps> = ({
  data: {
    articleDetailPage: { urls, seo, banner, description, relatedArticles },
    allArticleDetailPage: { articlesList },
  },
}) => (
  <Layout {...{ urls, seo }} background="sauvignon">
    <Banner {...banner} />
    <div className="article-detail-page__content">
      <Typography
        dangerouslySetInnerHTML={description}
        className="article-detail-page__description"
        size={{ base: 16, md: 20 }}
        inherit={false}
      />
      <RelatedList {...relatedArticles} relatedItems={articlesList} />
    </div>
  </Layout>
);

export const query = graphql`
  query ArticleDetailPageQuery($lang: String, $path: [String], $articlesUrls: [String]) {
    articleDetailPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      banner {
        ...BannerFragment
      }
      description
      relatedArticles {
        ...RelatedListFragment
      }
    }
    allArticleDetailPage(filter: { url: { in: $articlesUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

export default ArticleDetailPage;
